/* .contact-us-page { */
  /* width: 100vw;
  overflow: hidden; */
/* } */

.contact-bg-img {
  position: relative;
  height: 20vw;
  width: 100%;
  background: #14619b;
  margin-bottom: 2rem;
}
.img {
  object-fit: cover;
}

.contact-bg-img > img {
  max-width: 100%;
}
.contact-bg-img > h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: bold;
  font-size: 1.75rem;
}

.contact-container {
  display: flex;
  gap: 0 4rem;
  justify-content: space-around;
  margin-top: 2rem;
  padding: 0 6rem;
}

/*-------------------------------------------------right section-------------------------------------------------*/

.contact-title h2 {
  font-size: 1.75rem;
  /* justify-content: flex-start; */
  font-weight: bold;
}

/* .contact-title::before {
  content: "";
  position: absolute;
  bottom: 3px;
  left: 0;
  background-color: blue;
  width: 35%;
  height: 4px;
} */

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  margin-top: 2rem;
}

.icon-group {
  display: flex;
  gap: 0 1.5rem;
  align-items: center;
  font-size: 1.5rem;
}

.react-icon {
  font-size: 1.5rem;
  border-radius: 50%;
  padding: 1rem;
  background-color: #3f96d9;
}
.react-icon:hover {
  color: white;
}

.col-right {
  flex: 1;
  margin-top: 1rem;
}

.sendMessage h2 {
  font-size: 1.75rem;
  font-weight: bold;
}
.col-left {
  flex: 1;
  margin-top: 1rem;
}
/*------------------------------------------Form section-----------------------------------------*/

.message-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
}
.first {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
}
.first-div {
  display: flex;
  flex-direction: column;
}

.first .first-div input {
  padding: 8px;
  border-radius: 10px;
  gap: 0.5rem;
}

.first .first-div input::placeholder {
  color: #f3eded;
}

.input-group {
  margin-top: 0.5rem;
}

.input-group > input {
  width: 100%;
  padding: 8px;
  border-radius: 10px;
}

.input-group ::placeholder {
  color: #f3eded;
}
input:focus,
textarea:focus {
  border: 1px solid blue;
}

.input-group .contact-button {
  background-color: #3f96d9;
  color: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  margin-top: 0.5rem;
  text-align: center;
}

.input-group > textarea {
  width: 100%;
  height: 160px;
  padding: 10px;
  border-radius: 10px;
}

/*-----------------------------------------map section-------------------------------------------*/

.map-section {
  margin-top: 2rem;
  width: 100%;
  overflow: hidden; /* Ensure overflow is hidden */
}
.officeLocation {
  width: 100%;
  height: 60vh;
  max-width: 100%; /* Ensure the map does not exceed the container width */
}

.map-section > h2 {
  font-size: 2.75rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1.5rem;
  margin-top: 5rem;
  padding: 0 6rem;
}

/*-----------------------------------community section---------------------------------------------------*/

.join-social {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  height: 40vh;
}

.join-social > h1 {
  font-size: 2.75rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.join-social > ul {
  display: flex;
  font-size: 2rem;
  gap: 20px;
  margin-bottom: 4rem;
}

.join-social > ul > li:hover {
  color: white;
}

/* ---------------------------------repsonsive css for CONTACT US PAGE ------------------------------------------*/

/*media queries*/
@media only screen and (max-width: 1024px) {
  .contact-container {
    flex-direction: column;
    padding: 0 1rem;
  }

  .contact-container .col-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .contact-title h2 {
    font-size: 1.75rem;
  }
  .first {
    display: flex;
    flex-direction: column;
  }
  .first .first-div input {
    padding: 8px;
    border-radius: 10px;
    gap: 0.1rem;
  }
  .input-group {
    display: flex;
    flex-direction: column;
  }
  .first {
    gap: 0.5rem;
  }
  .join-social > h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .map-section > h2 {
    font-size: 1.75rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.5rem;
    margin-top: 5rem;
    padding: 0 6rem;
  }
  .sendMessage h2 {
    font-size: 1.75rem;
  }
  .contact-bg-img > h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-weight: bold;
    font-size: 1.75rem;
  }
  .contact-bg-img {
    position: relative;
    height: 40vw;
    width: 100%;
    background: #14619b;
  }
}
/* @media only screen and (max-width: 1024px) {
  .contact-bg-img {
    height: 20vh;
  }
  .contact-bg-img > h2 {
    font-size: 1.5rem;
  }
  .map-section > h3 {
    font-size: 1.75rem;
  }
} */
