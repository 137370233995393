.navbar {
  top: 0;
  left: 0;
  background: #14619b;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  width: 100%;
  z-index: 1000;
  transition: box-shadow 0.3s ease;
  margin: 0 auto;
  padding: 0 15rem 0 2rem;
}

.scrolled {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.logo {
  height: 50px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

.logo img{
  width: 90px;
}

.nav-menu {
  /* display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 88vw;
  justify-content: end;
  margin-right: 2rem; */
  display: flex;
  gap: 10px;
  padding-top:30px;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 100px;
  gap: 10px;
}

.nav-items {
  position: absolute;
  display: flex;
  color: white;
  top: -15px;
  right: 0;
  align-items: center;
  justify-content: center;
  height: 80px;
  font-size: 10px;
  padding: 0 30px;
  display: visible;
}

.nav-items .nav-p {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  font-size: 0.8rem;
  margin-left: 20px;
}
.nav-items .nav-p:hover {
  text-decoration: underline;
}
.nav-items-mobile {
  display: none;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  background-color: #14619b;
  border-radius: 4px;
  transition: background-color 0.2s ease-out;
}

.nav-links:hover,
.nav-links.active {
  background-color: #1888ff;
  color: white;
  z-index: 99;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
.rotate-caret {
  transition: transform 0.4s ease;
}

.rotate-caret.rotate180 {
  transform: rotate(180deg);
}

@media screen and (max-width: 1070px) {
  .navbar {
    background: #14619b;
    padding: 0 2rem;
    height: 80px;
  }

  .NavbarItems {
    position: relative;
  }
  .logo {
    margin-top: 0px;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    margin-top: 0px;
  }

  .nav-menu.active {
    background: #14619b;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
  }

  .nav-links:hover,
  .nav-links.active {
    background-color: #1888ff;
    border-radius: 0;
  }
  .nav-items {
    display: none;
  }

  .nav-items-mobile {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    height: 80px;
    gap: 10px;
  }

  .nav-items-mobile p {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: white;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  /* button {
    display: none;
  } */
}
