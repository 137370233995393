.Websites-service-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #055089;
  background: #67ace1;
  // .vid-icon-div {
  //   position: fixed;
  //   display: flex;
  //   right: 0;
  //   top: 50%;
  //   padding: 10px 25px;
  //   cursor: pointer;
  //   z-index: 1000;
  //   background: rgb(223, 207, 207);
  //   color: black;
  //   border-radius: 5px;
  // }

  .top-detail-div {
    .service-div {
      display: flex;

      .left-div,
      .right-div {
        margin: 0 auto;
        padding: 0 2rem;
        display: flex;
        flex-direction: column;
       
        flex: 1;
        p {
          color: #055089;
         
        }

        h2 {
          color: #055089;
          font-size: 2.75rem;
          font-weight: 600;
        }
        h3 {
          font-size: 24px;
          color: #055089;
          font-weight: 600;
        }

        img {
          width: 100%;
          height: 80%;
        }
      }
    }
  }

  .mid-div {
    display: flex;
    background: #3f96d9;
    color: #055089;

    .mid-left-div {
      margin: 0 auto;
      padding: 0 2rem;
      flex: 1;
      display: flex;

      flex-direction: column;

      h3 {
        color: #055089;
        font-size: 24px;
        font-weight: 600;
      }
      h2 {
        color: #055089;
        font-size: 2.75rem;
        font-weight: 600;
      }
      p {
        font-size: 19px;
      }
    }

    .mid-right-div {
      margin: 0 auto;
      padding: 0 2rem;
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 90px;
      align-items: center;

      justify-content: center;

      .image1,
      .image2,
      .image3 {
        width: 100%;
        height: auto;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .last-div {
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
    // margin: 0 auto;
    padding: 0 2rem;

    .last-left-div{
      text-align: center;
      display: flex;
      flex-direction: column;
      color: #055089;
      margin: 0 auto;
      padding: 0 2rem;
  
      h3 {
        color: #055089;
        font-size: 24px;
        font-weight: 600;
      }

    }

    .last-right-div{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        color: #055089;
        font-size: 19px;
      }

      h3 {
        color: #055089;
        font-size: 24px;
        font-weight: 600;
      }
    }

    p{
      size: 19px;
    }
  }
}
// responsive
@media screen and (max-width: 768px) {
  .top-detail-div {
    .service-div {
      display: flex;
      flex-direction: column;
    }
  }

  .mid-div {
    display: flex;
    flex-direction: column;
  }

  .last-div {
    display: flex;
    flex-direction: column;
    .last-left-div{
     width: 100%;
    }
   
  }
}

@media screen and (max-width: 425px) {
  .top-detail-div {
    display: flex;
    flex-direction: column;
  }

  .mid-div {
    display: flex;
    flex-direction: column;
  }

  .last-div {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 375px) {
  .top-detail-div {
    display: flex;
    flex-direction: column;
  }

  .mid-div {
    display: flex;
    flex-direction: column;
  }

  .last-div {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 320px) {
  .top-detail-div {
    display: flex;
    flex-direction: column;
  }

  .mid-div {
    display: flex;
    flex-direction: column;
  }

  .last-div {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 1024px) {
  .top-detail-div {
    display: flex;
    flex-direction: column;
  }

  .mid-div {
    display: flex;
    flex-direction: column;
  }

  .last-div {
    display: flex;
    flex-direction: column;
  }
}
