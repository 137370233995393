* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* min-height: 100vh; */
/* 
 body {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column; 
}  */

img{
  opacity: 90%;
}

footer {
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 80px;
  background-color: #14619b;
  /* background-image: url("../../assets/images/Flex-Carousel/footerimg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; */
}

.container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

@media (min-width: 768px) {
  .container {
    gap: 20px;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.wrapper .footer-widget {
  width: calc(20% - 30px);
  margin: 0 15px 50px;
  padding: 0 12px;
}

.wrapper .footer-widget:nth-child(1) {
  width: calc(40% - 50px);
  margin-right: 15px;
}


.wrapper .footer-widget .logo{
  height: 80px;
  width: 130px;
}
.wrapper .footer-widget .logo {
  margin-bottom: 30px;
  vertical-align: middle;
}

.wrapper .footer-widget p {
  margin-bottom: 30px;
  font-size: 19px;
  line-height: 24px;
  color: white;
}

.wrapper .footer-widget .socials {
  display: flex;
  align-items: center;
  /* justify-content: flex-start; */
}

.wrapper .footer-widget .socials li {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  color: white;
 
}

.wrapper .footer-widget .socials li a {
  height: 50px;
  width: 50px;
  margin-right: 10px;
  background-color: rgba(3, 127, 243, 0.5);
  border-radius: 50%;
  font-size: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-out;
  color: white;
}

.wrapper .footer-widget .socials li a:hover {
  background-color: #037ef3;
  color: white;
}


.wrapper .footer-widget h6 {
  color: #055089;
  margin: 10px 0 35px;
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.wrapper .footer-widget .links li {
  list-style: none;
  color: white;
}

.wrapper .footer-widget .links li a {
  font-size: 16px;
  text-decoration: none;
  text-transform: capitalize;
  line-height: 32px;
  transition: all 0.3s ease-out;
  color: white;
}

.wrapper .footer-widget .links li a:hover {
  color: white;
  background: #3f96d9;
  padding: 2px 5px;
  border-radius: 5px;
}

.copyright-wrapper {
  padding: 20px 0;
  border-top: 1px solid rgba(88, 89, 120, 0.4);
}

.copyright-wrapper p {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.copyright-wrapper p a {
  color: inherit;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease-out;
}

.copyright-wrapper p a:hover {
  color: #037ef3;
}

/* Let's write the media query */
@media (max-width: 992px) {
  .container {
    max-width: 960px;
  }

  .wrapper .footer-widget,
  .wrapper .footer-widget:nth-child(1) {
    width: calc(50% - 30px);
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 720px;
  }

  .wrapper .footer-widget,
  .wrapper .footer-widget:nth-child(1) {
    width: 100%;
    margin: 0 10px 50px;
  }
}

.footer-light-mode {
  /* background: linear-gradient(224deg,#eee5d3 20%,#fffaf4 81.18%); */
  background: #67ace1;
  color: #1d1d1d;
}
.footer-light-mode h3 {
  color: orange;
  /* color: white; */
}
.footer-light-mode p {
  color: #1d1d1d;
  /* color: white; */
}

.footer-light-mode .footer-widget h6 {
  color: orange;
  /* color: white; */
}
.footer-light-mode .wrapper .footer-widget .links li a {
  color: #1d1d1d;
  /* color: white; */
}

.footer-light-mode .wrapper .footer-widget .links li a:hover {
  background: blue;
  color: white;
  border-radius: 2px;
  padding: 2px 5px;
}

.footer-light-mode .wrapper .footer-widget .socials li a {
  background-color: transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  color: black;
}
.footer-light-mode .wrapper .footer-widget .socials li a:hover {
  color: white;
  background-color: blue;
}
