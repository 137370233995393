// .vid-icon-div {
//     position: fixed;
//     // position: absolute;
//     display: flex;
//     right: 0;
//     top: 50%;
//     padding: 10px 25px;
//     cursor: pointer;
//     z-index: 1000;
//     background: rgb(223, 207, 207);
//     color: black;
//     border-radius: 5px;
    
    
 // globals.scss

@media screen and (max-width:1024px) {
    
    .vid-div {
        min-height: 80vh;
        position: relative;

        video{
            height: 100%;
            width: fit-content;
            position: absolute;
            left:0;
            object-fit: cover;
        }
    }
}

@media screen and (max-width:600px) {
    
    .vid-div {
        min-height: 70vh;
        width: 250%;
        position: relative;
                
        video{
            position: absolute;
            left:-6%;
        }
    }
}

