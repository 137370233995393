.Expertise {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:20px 0 50px 0;
  background: #14619b;
  color: white;
    
    .text-div {
      margin-top: 40px;
      margin-bottom: 40px;
      text-align: center;
      p{
        // width: 70%;
        margin-bottom: 20px;
        font-size: 19px;
        text-align: justify;
        color: white;
      }
      h2 {
        font-size: 44px;
        color: white;
      }
    }
    .icon-div {
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap: 10px;
      list-style: none;
      text-decoration: none;
      gap: 20px;
      .list-card-div {
        gap: 20px;
        li {
          display: flex;
          align-items: center;
          justify-content: center;
  
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 100px;
            color: white;
          }
          .react {
            &:hover {
              color: #61dbfb;
            }
          }
          .python {
            &:hover {
              color: #ffd43b;
            }
          }
          .node {
            &:hover {
              color: #8cc84b;
            }
          }
          .html {
            &:hover {
              color: red;
            }
          }
          .csss {
            &:hover {
              color: blue;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .icon-div{
      flex-direction: column;
  
      .list-card-div {
        margin-bottom: 15px;
    
        li {
          width: 100%;
          text-align: center;
        }
      }
    }
    }
    
    // .expertise-dark-mode{
    //   color:white;
    //   background: black;
    // }
   
    // .expertise-light-mode{
    //   color:#1d1d1d;
    //   background: var(--background-yellow);
  
    //   h2{
    //     color: orange;
    //   }
    //   p{
    //     color: #1d1d1d;
    //   }
  
    //   .icon{
    //     color:black;
    //   }
    // }