@tailwind base;
@tailwind components;
@tailwind utilities;


.icon-hover{
  @apply transition-all ease-in-out duration-300
}

.text-hover {
   @apply after:content-[""] after:absolute after:-bottom-0.5 after:h-0.5 after:bg-extra-l-blue after:left-0 after:w-0 hover:after:w-full transition-all ease-in-out duration-200 after:transition-all after:duration-200 relative inline-block cursor-pointer;
}

.input-field{
  @apply focus:outline-none focus:border-none
}

.card-hover{
  @apply hover:scale-105 transition-all ease-in-out duration-300 
 }
