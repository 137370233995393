.testimonials{
    color: white;
}

.testimonials .item-list{
    margin-top: 30px;
    row-gap: 30px;
    color: 055089;
    
}
.testimonials .item{
    padding: 48px 16px;
    position: relative;
    transition: var(--transition);
}
.testimonials .item::after{
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0%;
    height: 5px;
    background: rgb(87, 100, 222);
    background: linear-gradient(45deg, rgba(87, 100, 222, 1) 0%, rgba(85, 179, 213, 1) 100%);
    transition: var(--transition);
}
.testimonials .item:hover::after{
    width: 100%;
}
.testimonials .item-img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 28px;
}
.testimonials .item-name{
    font-size: 25px;
}
.item{
    background:#fff;
    box-shadow: black; 
    color: #055089;
    border-radius: 6px;
    width: 300px;
    height: 350px;
}
.item:hover{
    
        background: #fcf9f9;
    
}


@media screen and (min-width: 768px){
    .testimonials .section-t{
        text-align: center;
    }
    .testimonials .section-t::before{
        left: 50%;
        transform: translateX(-50%);
    }
    .testimonials .item-list{
        grid-template-columns: repeat(2, 1fr);
        column-gap: 30px;
    }
}

@media screen and (min-width: 992px){
    .testimonials .item-list{
        grid-template-columns: repeat(3, 1fr);
    }
}

/* .about-light-mode{
    background: linear-gradient(247.2deg,#e3e5fc 8.64%,#f7f8fc 52.48%,#efeeeb 86.51%);
    background:  #67ACE1;
    color: black;
    h3,h4{
        color: orange;
    }
    p{
        color: #1d1d1d;
    }
    .item{
        background: transparent;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 

    }

} */