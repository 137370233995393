h2 {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.service-light-modes {
  color: #1d1d1d;
  background:linear-gradient(to right, #7eacff, #76c3ff,#88d6fb,#a9e6f5,#d0f4f5); 

}
.service-light-modes h2 {
  color: #055089;
}

/* .service-vid {
  height: 60vh;
  width: 60vw;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
}
.service-vid .video {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
} */
