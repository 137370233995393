.ProjectPage {
    background-image: linear-gradient(to right top, #7eacff, #76c3ff, #88d6fb, #a9e6f5, #d0f4f5);

    h3 {
        font-size: 2.75rem;
    }
}

.ProjectPage .item-list {
    margin-top: 10px;
    row-gap: 90px;
    color: 055089;

}

.ProjectPage .item {
    padding: 48px 16px;
    position: relative;
    transition: var(--transition);
}

.ProjectPage .item::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0%;
    height: 5px;
    background: rgb(87, 100, 222);
    background: linear-gradient(45deg, rgba(87, 100, 222, 1) 0%, rgba(85, 179, 213, 1) 100%);
    transition: var(--transition);
}

.ProjectPage .item:hover::after {
    width: 100%;
}

.ProjectPage .item-name {
    font-size: 1.5rem;
    font-weight: 600;

}

.ProjectPage .item-deadline {
    font-size: 19px;
    font-weight: 600;

}

.item {
    background: #ffffff;
    box-shadow: #3F96D9;
    color: #ffffff;

}

@media screen and (min-width: 936px) {
    .ProjectPage .section-t {
        text-align: center;
    }

    .ProjectPage .section-t::before {
        left: 50%;
        transform: translateX(-50%);
    }

    .ProjectPage .item-list {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 30px;
    }



    @media screen and (min-width: 768px) {
        .ProjectPage .section-t {
            text-align: center;
        }

        .ProjectPage .section-t::before {
            left: 50%;
            transform: translateX(-50%);
        }

        .ProjectPage .item-list {
            grid-template-columns: repeat(2, 1fr);
            column-gap: 30px;
        }
    }

    @media screen and (min-width: 992px) {
        .ProjectPage .item-list {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

