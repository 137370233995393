*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  /* color: #055809; */
}
h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  color: #055089;
  font-weight:400;
}


img {
  width: 100%;
  display: block;
}
.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
button {
  cursor: pointer;
  outline: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: 1.8rem;
}
input,
textarea,
select {
  outline: 0;
  /* border: none; */
  resize: none;
}

/* custom classes */
.container {
  /* max-width: 1440px; */
  margin: 0 auto;
  padding: 0 2rem;

}

/* flexbox and grid */
.flexs {
  display: flex;
  align-items: center;
}
.flexs-column {
  flex-direction: column;
}
.flexs-center {
  justify-content: center;
}
.flexs-between {
  justify-content: space-between;
}
.flexs-start {
  justify-content: flex-start;
}
.flexs-end {
  justify-content: flex-end;
}

.grid {
  display: grid;
}

/* height and width */
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}

/* text and paras */
.text {
  line-height: 1.55;
  font-weight: 300;
  font-size: 19px;
  color: #055089;
  font-weight: 400;
}
.text-lead {
  font-size: 2.5rem;
  line-height: 1.4;
  font-weight: 500;
  color: #055089;
}

/* text align */
.text-center {
  text-align: center;
}
.text-start {
  text-align: left;
}
.text-end {
  text-align: right;
}

/* text transform */
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}



/* font weights */
.fw-2 {
  font-weight: 200;
}
.fw-3 {
  font-weight: 300;
}
.fw-4 {
  font-weight: 400;
}
.fw-5 {
  font-weight: 500;
}
.fw-6 {
  font-weight: 600;
}
.fw-7 {
  font-weight: 700;
}
.fw-8 {
  font-weight: 800;
}
.fw-9 {
  font-weight: 900;
}

/* common font sizes */
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-19 {
  font-size: 19px;
}
.fs-17 {
  font-size: 17px;
}
.fs-18 {
  font-size: 18px;
}
.fs-19 {
  font-size: 19px;
}
.fs-20 {
  font-size: 20px;
}
.fs-21 {
  font-size: 21px;
}
.fs-22 {
  font-size: 22px;
}
.fs-23 {
  font-size: 23px;
}
.fs-24 {
  font-size: 24px;
}
.fs-25 {
  font-size: 25px;
}
.fs-26 {
  font-size: 26px;
}

/* letter spacing */
.ls-1 {
  letter-spacing: 1px;
}
.ls-2 {
  letter-spacing: 2px;
}

/* backgrounds */
.bg-transparent {
  background-color: transparent;
}
.bg-white {
  background-color: var(--clr-white);
}
.bg-black {
  background-color: var(--clr-black);
}




/* buttons */
.btn {
  display: inline-block;
  min-height: 48px;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  opacity: 0.95;
}
.btn-blue {
  background-color: var(--clr-robin-blue);
}

/* margin */
.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

/* animation and transition stopper */
.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}

/* sections */
.section-h {
  font-family: var(--fnt-base);
  font-size: 4.4rem;
  line-height: 1.4;
  font-weight: 600;
  margin-bottom: 8px;
}
.section-p {
  padding: 2rem 0;
}
.section-p-top {
  padding-top: 10rem;
}
.section-t {
  font-size: 44px;
  color: #055089;
  padding: 2rem 0;
  position: relative;
  line-height: 1.2;
}
.section-t p {
  margin-top: 26px;
}
.section-t::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  height: 5px;
  width: 60px;
  background: rgb(87, 100, 222);
  background: linear-gradient(
    45deg,
    rgba(87, 100, 222, 1) 0%,
    rgba(85, 179, 213, 1) 100%
  );
}
p{
  color: #055089;
}

/* item */
.item-title {
  margin: 18px 0;
}

@media screen and (min-width: 768px) {
  .section-t {
    text-align: left;
  }
  .section-t::before {
    left: 0;
    transform: translateX(0);
  }
}

/* hover effect */
.translate-effect:hover {
  transform: translateY(-20px);
}
