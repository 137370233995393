.dropdown-menu {
  background: #5cabff;
  width: 200px;
  position: absolute;
  top: 100px;
  list-style: none;
  text-align: start;
 
}


.service-dropdown-menu{
  background: #5cabff;
  width: 200px;
  position: absolute;
  top: 100px;
  list-style: none;
  text-align: start;
}

.dropdown-menu li {
  background: #5cabff;
  cursor: pointer;
}
.service-dropdown-menu li {
  background: #5cabff;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #2d8dd6;
}
.service-dropdown-menu li:hover {
  background: #2d8dd6;
}

.dropdown-menu.clicked {
  display: none;
}
.service-dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 10px;
}

@media screen and (max-width: 1070px) {
  /* .fa-caret-down {
    display: none;
  } */
  .dropdown-menu{
    top: 175px;
    left: 50px;
  }
  .service-dropdown-menu{
    top: 265px;
    left: 50px;
  }
}
