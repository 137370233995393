// .services-dark-mode{
//     background:#191919;
//     color: white;
//     span{
//         color: white;
//     }
// }
  

// .services-light-mode{ 
// box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 

//     .span-div{
//         color: rgb(57, 55, 55);
        
//     }
//     .text{
//         color:#1d1d1d;
//     }
//     p{
//         color: #1d1d1d;  
//     }
//     &:hover{
//         background:white ;
//         span{
//             color:black;
//         }
//         .text{
//             color:black;
//         }
//         p{
//             color: black;  
//         }
//     }

// }

.servicesCard{
box-shadow:0 0 10px #3F96D9;
background: #fff;
color: black;
margin-top: 0px;
&:hover{
    background: #fcf9f9;
}
.text{
    color: #055089 ;
}
}


