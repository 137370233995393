
.about{
  background:linear-gradient(to right, #7eacff, #76c3ff,#88d6fb,#a9e6f5,#d0f4f5); 
  

  .about-content{
    row-gap: 40px;
    padding: 0 2rem;
    // display: flex;;
    // flex-direction: column;
    // gap: 40px;

    // .content-right {
    //   // display: flex;

    //   img {
    //     animation: floatUpDown 5s ease-in-out infinite; 
    //   }
    // }

    h5{
      font-size: 44px;
     
      
    }
    button{
      background:#67ACE1;  
      color: white;
      width: fit-content;
      padding: 5px 10px;
      border-radius: 10px;
      font-size: 19px;
      cursor: pointer;
      margin-top: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    
      &:hover{
        background:#5bb2f4;  
      }
      
    
    }
    
}

.second-about-section{
  margin-top: 4rem;
  background: #14619b;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 2rem 5rem 2rem;
.text-div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2{
    color: white;
    font-size: 2.75rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  p{
    // width: 70%;/
    font-size: 19px;
    color: white;
    margin-bottom: 20px;
    text-align: justify;
   
  }

}
button{
  background:#67ACE1;  
  color: white;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 19px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  &:hover{
    background:#5bb2f4;  
  }
  

}
}
@keyframes floatUpDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); // Adjust the distance the image moves up and down
  }
}
}




@media screen and (min-width: 768px){
    
    .about-content{
        text-align: left;
    }
}

@media screen and (min-width: 992px){
    .about-content{
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        column-gap: 60px;
    }
}


/* .about-light-mode{
  background: #67ACE1;
    color: black;
  }
  .about-light-mode h3{
    color: orange;
  }
  .about-light-mode p {
    color: black;
  } */